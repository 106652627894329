import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { ocorrenciaTarefaStore } from '@/utils/store-accessor';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
let MigrarTarefasComponent = class MigrarTarefasComponent extends Vue {
    constructor() {
        super(...arguments);
        this.atribuidasVoceHeader = [
            { text: 'Criado por', value: 'criado_por_nome' },
            { text: 'Criado em', value: 'created_at' },
            { text: 'Aluno', value: 'entidade_nome' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Reunião', value: 'reuniao_tipo_id' },
            { text: 'Agendada para', value: 'data_hora_agendamento' },
        ];
        this.tarefasUsuario = [];
        this.selectedItems = [];
        this.tarefasUsuarioSearch = '';
        this.loading = false;
        this.selectedContaBancaria = null;
        this.ocorrenciaEntidadeSelected = {};
    }
    get tarefasPendentes() {
        const filtro = [0];
        return _.filter(this.tarefasUsuario, (i) => filtro.includes(i.status_id));
    }
    async migrar() {
        this.loading = true;
        // @ts-ignore
        if (this.ocorrenciaEntidadeSelected.id) {
            // @ts-ignore
            await ocorrenciaTarefaStore.migrarOcorrenciaTarefa({
                // @ts-ignore
                id: this.ocorrenciaEntidadeSelected.id,
                data: this.selectedItems,
            });
        }
        this.selectedItems = [];
        const res = await ocorrenciaTarefaStore.getOcorrenciaTarefasByUserId(this.id);
        this.tarefasUsuario = res.atribuidas_a_voce;
        this.loading = false;
    }
    async mounted() {
        const res = await ocorrenciaTarefaStore.getOcorrenciaTarefasByUserId(this.id);
        this.tarefasUsuario = res.atribuidas_a_voce;
    }
};
__decorate([
    Prop({ type: String, default: null })
], MigrarTarefasComponent.prototype, "id", void 0);
MigrarTarefasComponent = __decorate([
    Component({
        components: {
            SearchEntidadeComponent,
            EntidadeLinkComponent,
        },
    })
], MigrarTarefasComponent);
export default MigrarTarefasComponent;
