var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Tarefas do colaborador")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.tarefasUsuarioSearch),callback:function ($$v) {_vm.tarefasUsuarioSearch=$$v},expression:"tarefasUsuarioSearch"}}),_c('v-data-table',{attrs:{"headers":_vm.atribuidasVoceHeader,"items":_vm.tarefasPendentes,"search":_vm.tarefasUsuarioSearch,"show-select":"","footer-props":{
                itemsPerPageOptions:[-1]
              },"dense":"","hide-default-footer":"","height":"50vh"},scopedSlots:_vm._u([{key:"item.data_hora_agendamento",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(value))+" ")]}},{key:"item.reuniao_tipo_id",fn:function(ref){
              var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm.getOcorrenciaTipoReuniao(value))+" ")]):_c('span',[_vm._v(" Não ")])]}},{key:"item.entidade_nome",fn:function(ref){
              var item = ref.item;
return [_c('entidade-link-component',{attrs:{"propEntidadeId":item.entidade_id,"propEntidadeNome":item.entidade_nome,"propEntidadeTipo":item.entidade_tipo}})]}},{key:"item.created_at",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.descricao",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticStyle:{"width":"500px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(item.descricao))])]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),_c('v-row',[_c('v-col',[_c('search-entidade-component',{ref:"searchEntidadeComponent",attrs:{"propTitle":"Usuário para migrar a tarefa","propEntityType":['usuario'],"propShowInactive":false},model:{value:(_vm.ocorrenciaEntidadeSelected),callback:function ($$v) {_vm.ocorrenciaEntidadeSelected=$$v},expression:"ocorrenciaEntidadeSelected"}})],1),_c('v-col',[_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.selectedItems.length <= 0 || _vm.loading,"color":"primary"},on:{"click":function($event){return _vm.migrar()}}},[_vm._v(" migrar ")])],1)],1),_c('p',[_vm._v("É possível migrar as tarefas de um usuário para outro selecionando-o no campo acima e clicando no botão migrar. ")]),_c('span',[_vm._v("Total de tarefas selecionadas: "+_vm._s(_vm.selectedItems.length)+". ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }