export function newUser() {
    return {
        aluno_id: null,
        cargo_id: null,
        company_id: 0,
        departamento_id: null,
        email: null,
        full_name: '',
        is_active: true,
        is_aluno: false,
        is_superuser: false,
        login_2fa_enabled: false,
        tools: [],
    };
}
